import Axios from "axios";

//import Store from "@/store/store";
// import { useSideBarStore } from "@/components/dynamic/MapViewV2/LocalStore/SideBarStore";
// const sideBarStore = useSideBarStore();
export const state = {
  notifications: [],
  JUNotification: false,
  JUReceivedNotification: [],
  JUNotificationFromOutside: false,
  projectChangePopupStatus: false,
  currentNotificaiton: null
};
export const getters = {
  getCurrentNotification: state => {
    return state.currentNotificaiton;
  },
  getProjectChangePopupStatus: state => {
    return state.projectChangePopupStatus;
  },
  getJUNotificationFromOutside: state => {
    return state.JUNotificationFromOutside;
  },
  getJUReceivedNotification: state => {
    return state.JUReceivedNotification;
  },
  getRawNotificationsArr() {
    return state.notifications;
  },
  getNotifications: state => {
    let notificationData = [];
    const formatedJUNotifications = [];
    const formatedGenericNotifications = [];
    const formatedMVExportNotifications = [];
    if (state.notifications.length > 0) {
      // loop over notifications
      state.notifications.forEach(ele => {
        // if file exist in notificaiton, add it to formatedJUNotifications
        if (ele.File) {
          const obj = {
            projectId: ele.ProjectId,
            projectName: ele.ProjectName,
            notificationModule: ele.NotificationModule,
            files: { mainStatus: ele.Status, ...ele.File }
          };
          formatedJUNotifications.push(obj);
        }

        // if image file exist in notification, add it to formatedJUNotifications
        if (ele.ImageFile && ele.ImageFile.Status != "Cancelled") {
          const obj = {
            projectId: ele.ProjectId,
            projectName: ele.ProjectName,
            notificationModule: ele.NotificationModule,
            files: { mainStatus: ele.Status, ...ele.ImageFile }
          };
          formatedJUNotifications.push(obj);
        }

        // if notification is mv export notification, add it to formatedMVExportNotifications
        if (
          ele.type === "O360EXPORT" ||
          ele.type === "OsmoViewPDF" ||
          ele.type === "OsmoViewCSV" ||
          ele.type === "JU"
        ) {
          const obj = {
            id: ele.id,
            projectId: ele.projectId,
            title: ele.title,
            notificationModule: ele.notificationModule,
            message: ele.message,
            status: ele.status,
            type: ele.type,
            clickCallback: ele.clickCallback
          };
          formatedMVExportNotifications.push(obj);
        }

        // if generic notification exist, add it to formatedGenericNotifications
        if (ele.isGeneric) {
          const obj = {
            id: ele.Id,
            projectId: ele.ProjectId,
            title: ele.Title,
            notificationModule: ele.NotificationModule,
            message: ele.Message,
            status: ele.Status,
            type: ele.Type,
            clickCallback: ele.clickCallback
          };
          formatedGenericNotifications.push(obj);
        }
      });

      // Group Joint Use Notifications by project id
      const uniqueProjects = new Set(
        ...[formatedJUNotifications.map(a => a.projectId)]
      );
      uniqueProjects.forEach(b => {
        const notifications = formatedJUNotifications.filter(
          a => a.projectId == b
        );
        notificationData.push({
          projectId: b,
          projectName: notifications[0].projectName,
          notificationModule: notifications[0].notificationModule,
          files: notifications
        });
      });

      // Group O360 Export Notifications by project id
      const uniqueExportProjects = new Set(
        ...[formatedMVExportNotifications.map(a => a.projectId)]
      );
      uniqueExportProjects.forEach(b => {
        const notifications = formatedMVExportNotifications.filter(
          a => a.projectId == b
        );
        notificationData.push({
          projectId: b,
          title: notifications[0].title,
          notificationModule: notifications[0].notificationModule,
          files: notifications,
          type: notifications[0].type
        });
      });

      // add generic notifications to the list of notifications
      notificationData = notificationData.concat(formatedGenericNotifications);
    }
    return notificationData;
  },
  getJuNotifivationStatus: state => {
    return state.JUNotification;
  },
  formatO360ExportNotification: (state, rootGetters) => notificationObj => {
    // convert properties to camel case since that is how SignalR send them back
    for (const prop of Object.keys(notificationObj)) {
      if (prop.charAt(0) !== prop.charAt(0).toLowerCase()) {
        notificationObj[`${prop.charAt(0).toLowerCase()}${prop.slice(1)}`] =
          notificationObj[prop];
        delete notificationObj[prop];
      }
    }
    const notificationProjectInfo = rootGetters.getProjectInfo(
      notificationObj.projectId
    );
    if (process.env.VUE_APP_Use_MapViewV2)
      notificationObj.notificationModule = "mapview";
    else notificationObj.notificationModule = "mapviewv1";
    notificationObj.title = `${notificationProjectInfo?.projectName}: Map View`;
    notificationObj.message = `${notificationObj.id}`;
    notificationObj.clickCallback = () => {
      import("../../components/dynamic/MapViewV2/LocalStore/SideBarStore").then(
        ({ useSideBarStore }) => {
          const searchResultStore = useSideBarStore();
          searchResultStore.setExportManagerVisibility(true);
        }
      );
    };

    return notificationObj;
  },
  formatOsmoVisionPDFNotification: (state, rootGetters) => notificationObj => {
    // convert properties to camel case since that is how SignalR send them back
    for (const prop of Object.keys(notificationObj)) {
      if (prop.charAt(0) !== prop.charAt(0).toLowerCase()) {
        notificationObj[`${prop.charAt(0).toLowerCase()}${prop.slice(1)}`] =
          notificationObj[prop];
        delete notificationObj[prop];
      }
    }
    const notificationProjectInfo = rootGetters.getProjectInfo(
      notificationObj.projectId
    );

    notificationObj.notificationModule = "diivaviewer";
    notificationObj.title = `${notificationProjectInfo?.projectName}: OsmoView`;
    notificationObj.message = `${notificationObj.id}`;
    notificationObj.clickCallback = () => {
      import(
        "../../components/dynamic/DiivaViewer/LocalStore/SideBarStore"
      ).then(({ useSideBarStore }) => {
        const searchResultStore = useSideBarStore();
        searchResultStore.setExportManagerVisibility(true);
      });
    };

    return notificationObj;
  },
  formatJUImportNotification: (state, rootGetters) => notificationObj => {
    // convert properties to camel case since that is how SignalR send them back
    // for (const prop of Object.keys(notificationObj)) {
    //   if (prop.charAt(0) !== prop.charAt(0).toLowerCase()) {
    //     notificationObj[`${prop.charAt(0).toLowerCase()}${prop.slice(1)}`] =
    //       notificationObj[prop];
    //     delete notificationObj[prop];
    //   }
    // }

    // customerId in the notification is of this format "JU_<customerId>"
    const customerId = parseInt(notificationObj.CustomerId.split("_")[1]);

    const customerInfo = rootGetters.getCustomerInfo(customerId);

    const messageObj = JSON.parse(notificationObj.Message);
    const message = messageObj.message.replace(
      "<TIME_STAMP>",
      formatDateTimeAndConvrtToLocal(messageObj.timestamp)
    );
    console.log("customerInfo: ", customerInfo, notificationObj.CustomerId);
    notificationObj.NotificationModule = "jointuse";
    notificationObj.Title = notificationObj.Title.replace(
      "<CustomerName>",
      customerInfo?.customerName
    );
    notificationObj.Message = message;
    //notificationObj.isGeneric = true;
    return notificationObj;
  }
};
export const mutations = {
  SET_JUNOTIFICATION_FROM_OUTSIDE_JU(state, data) {
    state.JUNotificationFromOutside = data;
  },
  SET_NOTIFICATION_DATA(state, data) {
    state.notifications = data;
  },
  SET_CURRENT_NOTIFICATION(state, data) {
    state.currentNotificaiton = data;
  },

  SET_JU_NOTIFICATIONS_STATUS(state, data) {
    state.JUNotification = data;
  },
  SET_JU_RECEIVED_NOTIFICATION_DATA(state, data) {
    state.JUReceivedNotification = data;
  },
  SET_SCREEN_CHANGE_REQUIRED_ON_NOTIFICATION(state, data) {
    state.projectChangePopupStatus = data;
  }
};
export const actions = {
  removeNotification({ dispatch, state }, id) {
    const notifications = state.notifications.filter(ele => {
      return ele.id != id;
    });
    dispatch("setNotificationData", notifications);
  },
  removeProjectNotifications(
    { dispatch, state, rootGetters },
    params = { projectId: null, type: null }
  ) {
    const notifications = state.notifications.filter(ele => {
      return ele.projectId != params.projectId || ele.type != params.type;
    });
    dispatch("setNotificationData", notifications);
  },
  removeO360ExportNotifications({ dispatch, state }, projectId) {
    let notificationsToRemove;
    let transactionsToRemove;
    if (projectId) {
      notificationsToRemove = state.notifications.filter(ele => {
        return (
          (ele.projectId == projectId && ele.type == "O360EXPORT") ||
          ele.type == "OsmoViewPDF" ||
          ele.type == "OsmoViewCSV"
        );
      });
    } else {
      notificationsToRemove = state.notifications.filter(ele => {
        return (
          ele.type == "O360EXPORT" ||
          ele.type == "OsmoViewPDF" ||
          ele.type == "OsmoViewCSV"
        );
      });

      transactionsToRemove = state.notifications.filter(ele => {
        return ele.isGeneric == true;
      });
    }

    let notificationsIdsToRemove = notificationsToRemove?.map(notification => {
      return { id: notification.id, customerId: notification.customerId };
    });

    const transactionIdsToRemove = transactionsToRemove?.map(notification => {
      return { id: notification.Id, customerId: notification.CustomerId };
    });

    notificationsIdsToRemove = notificationsIdsToRemove.concat(
      transactionIdsToRemove
    );
    if (notificationsIdsToRemove.length) {
      dispatch("sendReadReceipt", {
        ids: notificationsIdsToRemove
      });
    }
  },
  removeOsmoVisionPDFNotifications({ dispatch, state }, projectId) {
    let notificationsToRemove;
    if (projectId) {
      notificationsToRemove = state.notifications.filter(ele => {
        return ele.projectId == projectId && ele.type == "OsmoViewPDF";
      });
    } else {
      notificationsToRemove = state.notifications.filter(ele => {
        return ele.type == "OsmoViewPDF";
      });
    }

    const notificationsIdsToRemove = notificationsToRemove?.map(
      notification => {
        return { id: notification.id, customerId: notification.customerId };
      }
    );

    if (notificationsToRemove.length) {
      dispatch("sendReadReceipt", {
        ids: notificationsIdsToRemove
      });
    }
  },
  removeJUImportNotifications({ dispatch, state }) {
    const notificationsToRemove = state.notifications.filter(ele => {
      return ele.type == "JU";
    });

    const notificationsIdsToRemove = notificationsToRemove?.map(
      notification => {
        return { id: notification.id, customerId: notification.customerId };
      }
    );

    if (notificationsToRemove.length) {
      dispatch("sendReadReceipt", {
        ids: notificationsIdsToRemove
      });
    }
  },
  setNotificationData({ commit }, data) {
    commit("SET_NOTIFICATION_DATA", data);
  },

  setCurrenNotification({ commit }, data) {
    commit("SET_CURRENT_NOTIFICATION", data);
  },
  setJUReceivedNotificationdata({ commit }, data) {
    commit("SET_JU_RECEIVED_NOTIFICATION_DATA", data);
  },
  showScreenChangeNotificationWarningModal({ commit }, bool) {
    commit("SET_SCREEN_CHANGE_REQUIRED_ON_NOTIFICATION", bool);
  },
  getMVUnReadExportNotifications({ getters, rootGetters, dispatch }) {
    const url = `${process.env.VUE_APP_GetMVUnReadExportNotifications}/${getters.userId}`;

    Axios.get(url, {
      headers: {
        Authorization: `Bearer ${rootGetters.authToken}`
      }
    })
      .then(response => {
        const oldNotificationList = getters.getRawNotificationsArr;
        const newNotifications = [];
        // process notifications
        for (const notification of response.data?.Notifications) {
          if (notification.Type === "O360EXPORT") {
            newNotifications.push(
              getters.formatO360ExportNotification(notification)
            );
          } else if (notification.Type === "JU") {
            newNotifications.push(
              getters.formatJUImportNotification(notification)
            );
          } else if (
            notification.Type == "OsmoViewPDF" ||
            notification.Type == "OsmoViewCSV"
          )
            newNotifications.push(
              getters.formatOsmoVisionPDFNotification(notification)
            );
          else {
            notification.isGeneric = true;
            newNotifications.push(notification);
          }
        }
        const newNotificationsList =
          oldNotificationList.concat(newNotifications);
        dispatch("setNotificationData", newNotificationsList);
      })
      .catch(error => {
        console.log("Notifications Error: ", error);
      });
  },
  sendReadReceipt(
    { rootGetters, dispatch },
    params = { ids: [], customerId: null }
  ) {
    for (const obj of params.ids) {
      const url = `${process.env.VUE_APP_updateMVExportRecordReadReceipt}/${obj.id}/${obj.customerId}`;
      Axios.get(url, {
        headers: {
          Authorization: `Bearer ${rootGetters.authToken}`
        }
      })
        .then(response => {
          console.log("removed notificaiton: ", response);
          dispatch("removeNotification", obj.id);
        })
        .catch(error => {
          console.log("Notifications sending read receipt error: ", error);
          const errorObj = {
            requestName: "sendReadReceipt",
            errorMessage:
              "There seems to be an issue with updating notifications. If this error continues to occur, please contact support.",
            Error: error
          };
          //Throw error in the UI.
          dispatch("updateError", errorObj);
        });
    }
  }
};

function formatDateTimeAndConvrtToLocal(timestamp) {
  const utcTime = new Date(timestamp);

  // Convert to local time
  const localTime = utcTime.toLocaleString();
  return localTime;
}
